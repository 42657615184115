<template>
  <div>
    <div v-if="userProfiles.length > 0">
        <user-company-row v-for="(profile, index) in userProfiles"
                          :key="`user-company-row_${index}`"
                          :profile="profile"
                          :close-modal="closeModal">

        </user-company-row>
    </div>
    <b-card
      v-if="isOperative"
      class="mt-3 border-0"
      body-class="p-2 bg-gray-200 text-right"
    >
        <b-button
          variant="success"
          size="sm"
          @click="importCompanyRole"
        >
          {{addCompaniesCta}}
        </b-button>
    </b-card>
    <modal-add-company id="addCompany" />
  </div>
</template>

<script>
import { createPublicUri } from '@/utils/utilities';
import { toastSuccess } from '@/utils/toast';
import { formatDecimal } from '@/utils/formatterHelper';

const ModalAddCompany = () => import('@/components/userSections/ModalAddCompany.vue');
const UserCompanyRow = () => import('@/components/userSections/UserCompanyRow.vue');

const productType = 'pr_person_other';
const productCode = 'pr_person_roles';

export default {
  name: 'UserCompanies',
  components: { UserCompanyRow, ModalAddCompany },
  props: {
    closeModal: Function,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      paymentFailed: false,
    };
  },
  computed: {
    userProfiles() {
      return this.$store.getters['subject/currentUserProfiles'] || [];
    },
    roleProductPrice() {
      return this.$store.getters['wallet/getPricing'](productType, productCode);
    },
    rolePrice() {
      const product = this.roleProductPrice;
      console.debug('role price', product);
      if (product.price.amount < 0) {
        return 'N.A.';
      }
      return `${formatDecimal(product.price.amount, 'it')} ${product.price.currency}`;
    },
    addCompaniesCta() {
      return `Importa Cariche Aziende (${this.rolePrice})`;
    },
    activityStatus() {
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  mounted() {
    this.paymentFailed = false;
    if (!this.$store.getters['auth/isLogged']) {
      return;
    }

    this.$store.dispatch('subject/loadUserProfiles', 'person');
    this.$store.dispatch('wallet/loadPricingProduct');
  },
  methods: {
    addCompanyModal() {
      this.$bvModal.show('addCompany');
    },
    profileImgStyle(img) {
      const imgUrl = img.length
        ? createPublicUri(img[0].documentUpload.externalId)
        : this.userPlaceholder;
      return `background-image: url(${imgUrl});`;
    },
    changeProfile(profile) {
      console.debug('UserCompany changeprofile setpreloader', true);
      this.$store.dispatch('setPreloader', true);

      this.$store.dispatch('subject/setCurrentProfile', profile).then(() => {
        console.debug('UserCompany loaded setpreloader', false);
        this.$store.dispatch('setPreloader', false);
        this.routeTo('/dashboard-utente');
      },
      (error) => {
        console.log('Error in change profile', error);
        console.debug('UserCompany error setpreloader', false);
        this.$store.dispatch('setPreloader', false);
      });

      if (this.closeModal) this.closeModal();
    },
    routeTo(path) {
      this.$router.replace(path);
    },

    importCompanyRole() {
      this.paymentFailed = false;
      // console.debug('Importa aziende dove hai cariche');
      const message = `L' importazione dei ruoli societari prevede un costo di ${this.rolePrice}. Vuoi procedere con l'acquisto?`;
      this.$confirm(
        {
          message,
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: (confirm) => {
            if (confirm) {
              const person = this.$store.getters['subject/currentPerson'];
              this.$store.dispatch('wallet/createTransaction', {
                entityType: 'person',
                entityId: person.personId,
                description: 'Importazione ruoli societari',
                productType,
                productCode,
                paymentFor: {
                  entityClass: 'org.trendframework.database.entity.subjects.Person',
                  entityId: person.personId,
                },
              }).then((txCreated) => {
                toastSuccess('Acquisto completato con successo. Richiesta di recupero inviata');
                console.log('txCreated', txCreated);
                this.completed = true;
                this.submitted = false;
              }).catch((errorTx) => {
                console.log('errorTx', errorTx);
                this.paymentFailed = true;
                this.completed = true;
                this.submitted = false;
              });
            }
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
